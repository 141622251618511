* {
  text-decoration: none;
  list-style: none;
  
}

body::-webkit-scrollbar {display:none;}

.screenshot{
  padding: 1rem;
}

body {
  font-family: Montserrat; 
  
}

.wlogo{
  display: inline-block;
  margin-left: 6rem;
  margin-top: 0rem;
  padding: 0rem;
  z-index: 102;
  
}
.App-section{
  padding-left: 30rem;
  padding-right: 10rem;
}
.main{
  overflow-x: hidden;
}

.main-screenshot{
 
}

.footer{
  background-color: #242F3C;
  min-height: 40px;
}

nav {
  height: 50px;
  margin-top: 20px;
  width: 100%;
  background: transparent;
}

nav .leftSide {
  display: block;
  float: right;
  margin-right: 10px;
}

nav .leftSide #hidden {
  display: block;
  float: right;
  margin-right: 10px;
}

nav li {
  display: inline-block;
  margin: 0 18px;
}

nav a {
  color: #a7d139;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
}

a.active,
a:hover {
  color: #03aa58;
  transition: 0.5s;
}

nav #icon {
  color: #03aa58;
  font-size: 30px;
  line-height: 80px;
  float: right;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
.communities {
  background: #88A73E;
  min-height: 50vh;
  padding: 3rem;
}
.community-header{
  color: #ffffff;
  text-align: center;
  font-weight: 700;
}

.community-africa{
  color: #1c1e21;
}

.community-textafrica{
color: #1c1e21;
text-align: center;
line-height: 2.2rem;
margin-left: 20%;
margin-right: 20%;
font-size: 19px;

}

.community-text{
color: #ffffff;
text-align: center;
line-height: 2.2rem;
margin-left: 20%;
margin-right: 20%;
font-size: 19px;
}
.africa{
  background: #FBF9EF;
  min-height: 50vh;
  padding: 3rem;
}
.App { 
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #333333;
}
.App-header-2 {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333333;
}
.App-header-3 {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.App-header-4 {
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}
.explore-button{
  border-radius: 60px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top:15px;
  padding-bottom: 15px;
  background-color: transparent;
  font-size: 18px;
  text-transform: uppercase;
  border-color: #215645;
  color: #215645;
  cursor: pointer;
  letter-spacing: .5em;
}
.friends{
  margin-right: 10px;
  margin-top:80px;
}
.family{
  margin-right: 10px;
  margin-bottom:200px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.heading {
  font-size: 33px;
  font-weight: 400;
  line-height: 35px;
  color: #1c1e21;
  width: 500px;
  
}
.subheading {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #282828;
}
.subheading-2 {
  font-size: 15px;
  line-height: 22px;
  color: #282828;
}
.list-style {
  list-style: none;
}
.list-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  margin-top: 2rem;
  
}
.list-div a{
  cursor: pointer;
}
a {
  text-decoration: none;
  cursor: pointer;
}

.image-holder {
  margin-top: 40px;
}
@media (max-width: 909px) {
  .App {
  text-align: left;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

}
  nav ul {
    position: absolute;
    width: 100%;
    height: 200%;
    background: #2f3640;
    top: 80px;
    left: 0px;
    text-align: center;
     z-index: 99;
     transition: all 1s;
  }
  .parent {
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
}
.parent > .child {
    display: table-cell;
    vertical-align: middle;
}

  .subheading-mobile {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  
  }
    .heading-mobile {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  color: #1c1e21;
}

  .explore-button{
  border-radius: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top:5px;
  padding-bottom: 5px;
  background-color: transparent;
  font-size: 18px;
  text-transform: uppercase;
  border-color: #215645;
  color: #215645;
  cursor: pointer;
  letter-spacing: .2em;
}

  .community-textafrica{
color: #1c1e21;
text-align: center;
line-height: 2.2rem;
margin-left: 5%;
margin-right: 5%;
font-size: 15px;

}

  .friends{
  margin-right: 10px;
  margin-top:80px;

}
.family{
  margin-right: 10px;
  margin-bottom:200px;

}

  .heading {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  color: #1c1e21;
  
}

  .community-text{
color: #ffffff;
text-align: center;
line-height: 1.6rem;
margin-left: 0;
margin-right: 0;
}
  nav ul li {
    border-bottom: 1px solid #215645;
    width: 80%;
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: .2rem;
    transition: all .5s;
  }
  nav a {
    font-size: 20px;
  }
  nav #icon {
    display: block;
  }

  nav li {
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }

  nav .leftSide .links {
    display: none;
  }

  /* nav .leftSide #hidden {
    position: absolute;
    width: 100%;
    min-height:100vh;
    top: 60px;
    left: 0;
    text-align: center;
    transition: all 1s;
    z-index: 99;
  } */

  .image-holder {
    margin-top: 0px;
  }
}

@media (max-width: 440px) {
  .App {
  text-align: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App-section{
  padding: 0rem;
}
   nav ul {
    position: absolute;
    width: 100%;
    height: 200%;
    background: #2f3640;
    top: 80px;
    left: 0px;
    text-align: center;
     z-index: 99;
     transition: all 1s;
  }
  .parent {
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
}
.parent > .child {
    display: table-cell;
    vertical-align: middle;
}

  .subheading-mobile {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  
  }
    .heading-mobile {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  color: #1c1e21;
}
.friends{
  margin-right: 10px;
  margin-top:80px;
  width: 100px !important;
}
.family{
  margin-right: 10px;
  margin-bottom:200px;
  width: 100px !important;
}
  .community-textafrica{
color: #1c1e21;
text-align: center;
line-height: 2.2rem;
margin-left: 5%;
margin-right: 5%;
font-size: 15px;

}
.community-text{
color: #ffffff;
text-align: center;
line-height: 2.2rem;
margin-left: 5%;
margin-right: 5%;
font-size: 19px;
}
.explore-button{
  border-radius: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top:5px;
  padding-bottom: 5px;
  background-color: transparent;
  font-size: 18px;
  text-transform: uppercase;
  border-color: #215645;
  color: #215645;
  cursor: pointer;
  letter-spacing: .2em;
}
}
@media (max-width: 408px) {
  .App {
  text-align: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

   nav ul {
    position: absolute;
    width: 100%;
    height: 200%;
    background: #2f3640;
    top: 80px;
    left: 0px;
    text-align: center;
     z-index: 99;
     transition: all 1s;
  }

  .parent {
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
}
.parent > .child {
    display: table-cell;
    vertical-align: middle;
}

  .subheading-mobile {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  
  }


    .heading-mobile {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  color: #1c1e21;
  
}

  .subheading {
    font-size: 16px;
    padding: 12px;
     margin-left: 26px;
      width: 100%;
  }
  .image-holder {
    margin-top: -50px;
    
  }
  .heading {
  font-size: 18px;
  margin-left: 38px;
  margin-top: 30px;
  width: 100%;
  font-weight: 400;
  line-height: 31px;
  color: #1c1e21;
  text-align: center;
  
}
.friends{
  margin-right: 10px !important;
  margin-top: 10px !important;
}
.family{
  margin-right: 10px !important;
  margin-bottom: 120px !important;
}
  .community-textafrica{
color: #1c1e21;
text-align: center;
line-height: 2.2rem;
margin-left: 10%;
margin-right: 10%;
font-size: 15px;

}

.community-text{
color: #ffffff;
text-align: center;
line-height: 2.2rem;
margin-left: 5%;
margin-right: 5%;
font-size: 19px;
}
.explore-button{
  border-radius: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top:5px;
  padding-bottom: 5px;
  background-color: transparent;
  font-size: 18px;
  text-transform: uppercase;
  border-color: #215645;
  color: #215645;
  cursor: pointer;
  letter-spacing: .2em;
}

}
@media (max-width: 369px) {
  .App {
  text-align: left;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}
   nav ul {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #2f3640;
    top: 80px;
    left: 0px;
    text-align: center;
     z-index: 99;
     transition: all 1s;
  }
  .parent {
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
}
.parent > .child {
    display: table-cell;
    vertical-align: middle;
}

  .heading-mobile {
    font-size: large;
  }
  .subheading-mobile {
    font-size: small;
  }

  .image-holder {
    margin-top: -50px;
  }
    .heading {
  font-size: 2px;
  font-weight: 400;
  line-height: 31px;
  color: #1c1e21;
  
}
.friends{
  margin-right: 10px !important;
  margin-top: 0px !important;
}
.family{
  margin-right: 10px !important;
  margin-bottom: 0px !important;
}
  .community-textafrica{
color: #1c1e21;
text-align: center;
line-height: 2.2rem;
margin-left: 10%;
margin-right: 10%;
font-size: 15px;
}
.community-text{
color: #ffffff;
text-align: center;
line-height: 2.2rem;
margin-left: 5%;
margin-right: 5%;
font-size: 19px;
}

.explore-button{
  border-radius: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top:5px;
  padding-bottom: 5px;
  background-color: transparent;
  font-size: 18px;
  text-transform: uppercase;
  border-color: #215645;
  color: #215645;
  cursor: pointer;
  letter-spacing: .2em;
}
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
